
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as usersnYL_45KARzyZU3DFxc4DOb7SLu5_QjZ_45H_DWa_nhPDMYQMeta } from "/opt/render/project/src/apps/admin/client/pages/users.vue?macro=true";
import { default as indexXr0nQQdDHrO8PVrHFz3zRRy3xw_ugXmyEQdtZ6azfwAMeta } from "/opt/render/project/src/apps/admin/client/pages/emails/index.vue?macro=true";
import { default as _91templateId_93LYl4PVNkoEr_45mHBCdRsXqpBffUtBu9mRJ3mpk2u_G5gMeta } from "/opt/render/project/src/apps/admin/client/pages/emails/[templateId].vue?macro=true";
import { default as emailsQv4r2WARHzLMc7yrxfnwAPmRUojrevx7bKcct0k3Dk4Meta } from "/opt/render/project/src/apps/admin/client/pages/emails.vue?macro=true";
import { default as projects9ndtH7cfuzIBm76n10IrY_455qRtP1DcAWV_45413Y_xqVwMeta } from "/opt/render/project/src/apps/admin/client/pages/projects.vue?macro=true";
import { default as settingsgSEwS_QkNMxygdBzOlm8_WFjJ4_45pfe4VvSO66Z097MkMeta } from "/opt/render/project/src/apps/admin/client/pages/settings.vue?macro=true";
import { default as companiesHZsHOckb51rYrmuDOK6K1CcS23RaKWLFi61QHi_45Ms_450Meta } from "/opt/render/project/src/apps/admin/client/pages/companies.vue?macro=true";
import { default as loginnoUMMHIXCNi7SXdBB8fzPjNQrqbCUDjEWLLRdH5a27UMeta } from "/opt/render/project/src/apps/admin/client/pages/auth/login.vue?macro=true";
import { default as newMpYTRV1lLQ13ZLXstKUerYjzU9rJfsERjm7jaQRhnrYMeta } from "/opt/render/project/src/apps/admin/client/pages/courses/new.vue?macro=true";
import { default as indexyIVImTlZtOPQphr6Y8QELn6g_gOhjH9itFYmPVu35cQMeta } from "/opt/render/project/src/apps/admin/client/pages/places/index.vue?macro=true";
import { default as newyLNK5zRxgVu9OFbMNTPvAS0K2GR1Se_VKlwyU5EzLyIMeta } from "/opt/render/project/src/apps/admin/client/pages/teachers/new.vue?macro=true";
import { default as callback7sMJ11eJH8LE8VVN8qbcM_etwygCd1cS1XURHaHHpWsMeta } from "/opt/render/project/src/apps/admin/client/pages/auth/callback.vue?macro=true";
import { default as indexO_45qgMvfUz2nSpYXUo0DJNYHY6fL1rNojCi_45QVNw56vAMeta } from "/opt/render/project/src/apps/admin/client/pages/courses/index.vue?macro=true";
import { default as indexZla1WIu9b41Veu5lbPIqEnqkpF5xrNitHa0ubErUNgEMeta } from "/opt/render/project/src/apps/admin/client/pages/sessions/index.vue?macro=true";
import { default as _91_46_46_46path_93B7We5NpafcbgMceeGOcADlh15v_QsimEEFvmfCFpjYAMeta } from "/opt/render/project/src/apps/admin/client/pages/tags/[...path].vue?macro=true";
import { default as index_45old2OEBK8woQfKLNonTzbT5zwNy1phcHsCfIxtZ4uNELc0Meta } from "/opt/render/project/src/apps/admin/client/pages/tags/index-old.vue?macro=true";
import { default as indexMhWQ4sHYMZPhz0jzvVNKMGDCPp5JFP8rfRxKk6MjAxsMeta } from "/opt/render/project/src/apps/admin/client/pages/teachers/index.vue?macro=true";
import { default as indexKeD7wd1MjSlSDFyX3_45aUyaX4_doN5O541UaE24JTxQAMeta } from "/opt/render/project/src/apps/admin/client/pages/proposals/index.vue?macro=true";
import { default as index4SIXFBe3VcE8328Yvb6ICn5bEbCS4o9BkWW6OS9wEjEMeta } from "/opt/render/project/src/apps/admin/client/pages/tag-pages/index.vue?macro=true";
import { default as index91W_XCk1C_45Wv5lYsU_ZcV82tijtuA1v5pb12o1e__S4Meta } from "/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/index.vue?macro=true";
import { default as mediasgb2TtQOveRCFzwf599FWGQs5lw_45Yuo8_sPN8rmrPTFIMeta } from "/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/medias.vue?macro=true";
import { default as programz5zh_45pcPR_459WU8lkTJUfGJFMUqHLjg3up1zRqIfE8hwMeta } from "/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/program.vue?macro=true";
import { default as sessionsb5Kp8MXX9SlHLmqC4MgNfS7qX3f0bHDe9VOMaGE9uJ4Meta } from "/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/sessions.vue?macro=true";
import { default as resourcesyJjzLQNzJXJfY70E_45oa01OJI2DGttiBO_45Ikz7MyvUYIMeta } from "/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/resources.vue?macro=true";
import { default as _91courseId_932QsulA21wozWNvQvmywSY3FMciF9wvQEywNiKHNcJHUMeta } from "/opt/render/project/src/apps/admin/client/pages/courses/[courseId].vue?macro=true";
import { default as indexg2bIj_45Si_YLazYLyWChbUl54KIcT_CGSYFAbM4tXEecMeta } from "/opt/render/project/src/apps/admin/client/pages/organizations/index.vue?macro=true";
import { default as indexxoHW20lNHUiZarjnyRm9EnkuZ5cJNk_45rZY7Jv6LEu8MMeta } from "/opt/render/project/src/apps/admin/client/pages/proposals/[id]/index.vue?macro=true";
import { default as index_1gHawnbyKSyLGncto5pKb7c5BuVIsUDcovw7TQqwXAMeta } from "/opt/render/project/src/apps/admin/client/pages/teachers/[teacherId]/index.vue?macro=true";
import { default as coursessrvukSTfN7gVLmFwT_45bjg4Pw3AkJGuGSTdF8eNUXysEMeta } from "/opt/render/project/src/apps/admin/client/pages/teachers/[teacherId]/courses.vue?macro=true";
import { default as _91teacherId_93gU7Qp5FUHRcuJfFYORUkzZV5jyHLUFUvGdj5NOlpn3EMeta } from "/opt/render/project/src/apps/admin/client/pages/teachers/[teacherId].vue?macro=true";
import { default as _91playback_id_937FWbJhZSaWxudbGnQ9F0XOIikdq6dlMtr0NBIppbDQcMeta } from "/opt/render/project/src/apps/admin/client/pages/videos/[playback_id].vue?macro=true";
import { default as index69rHxW4LvQKimTKrPrJxjrS_3g8v5fMzixUqv70cw_45EMeta } from "/opt/render/project/src/apps/admin/client/pages/sessions/[sessionId]/index.vue?macro=true";
import { default as indexn7zqB1iotqOEdcOmFA9_OxGJb2XmUsxV_45xxFFuBsMvEMeta } from "/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/index.vue?macro=true";
import { default as tracksxI_OZHnPh_45K_q8m5NhE9rPHUG0O_453_45Mw20FHU9thI6YMeta } from "/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/tracks.vue?macro=true";
import { default as creditsgsuTgryFdgHb5qKfOuwl9DC_nmhL08wRIGHpIp51_45C0Meta } from "/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/credits.vue?macro=true";
import { default as sessionsjm5NL20mk4_45kx8cMUOBw455ZhuXjrLHoO1tkUxxfF_45wMeta } from "/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/sessions.vue?macro=true";
import { default as invitationsmNCCX6zY9VDAsFS03HdKrNs3vVyes37ptYVcbHYVTFgMeta } from "/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/invitations.vue?macro=true";
import { default as _91organizationId_93XS7bmzsGOQSU9TZrwqMsYGjuZiHGpWOQb1xBmNNDu9EMeta } from "/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId].vue?macro=true";
export default [
  {
    name: "users",
    path: "/users",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/users.vue")
  },
  {
    name: emailsQv4r2WARHzLMc7yrxfnwAPmRUojrevx7bKcct0k3Dk4Meta?.name,
    path: "/emails",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/emails.vue"),
    children: [
  {
    name: "emails",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/emails/index.vue")
  },
  {
    name: "emails-templateId",
    path: ":templateId()",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/emails/[templateId].vue")
  }
]
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/projects.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/settings.vue")
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/companies.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginnoUMMHIXCNi7SXdBB8fzPjNQrqbCUDjEWLLRdH5a27UMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/auth/login.vue")
  },
  {
    name: "courses-new",
    path: "/courses/new",
    meta: newMpYTRV1lLQ13ZLXstKUerYjzU9rJfsERjm7jaQRhnrYMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/new.vue")
  },
  {
    name: "places",
    path: "/places",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/places/index.vue")
  },
  {
    name: "teachers-new",
    path: "/teachers/new",
    meta: newyLNK5zRxgVu9OFbMNTPvAS0K2GR1Se_VKlwyU5EzLyIMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/teachers/new.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: callback7sMJ11eJH8LE8VVN8qbcM_etwygCd1cS1XURHaHHpWsMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/auth/callback.vue")
  },
  {
    name: "courses",
    path: "/courses",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/index.vue")
  },
  {
    name: "sessions",
    path: "/sessions",
    meta: indexZla1WIu9b41Veu5lbPIqEnqkpF5xrNitHa0ubErUNgEMeta || {},
    alias: ["/"],
    component: () => import("/opt/render/project/src/apps/admin/client/pages/sessions/index.vue")
  },
  {
    name: "tags-path",
    path: "/tags/:path(.*)*",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/tags/[...path].vue")
  },
  {
    name: "tags-index-old",
    path: "/tags/index-old",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/tags/index-old.vue")
  },
  {
    name: "teachers",
    path: "/teachers",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/teachers/index.vue")
  },
  {
    name: "proposals",
    path: "/proposals",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/proposals/index.vue")
  },
  {
    name: "tag-pages",
    path: "/tag-pages",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/tag-pages/index.vue")
  },
  {
    name: _91courseId_932QsulA21wozWNvQvmywSY3FMciF9wvQEywNiKHNcJHUMeta?.name,
    path: "/courses/:courseId()",
    meta: _91courseId_932QsulA21wozWNvQvmywSY3FMciF9wvQEywNiKHNcJHUMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/[courseId].vue"),
    children: [
  {
    name: "courses-courseId",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/index.vue")
  },
  {
    name: "courses-courseId-medias",
    path: "medias",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/medias.vue")
  },
  {
    name: "courses-courseId-program",
    path: "program",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/program.vue")
  },
  {
    name: "courses-courseId-sessions",
    path: "sessions",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/sessions.vue")
  },
  {
    name: "courses-courseId-resources",
    path: "resources",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/resources.vue")
  }
]
  },
  {
    name: "organizations",
    path: "/organizations",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/index.vue")
  },
  {
    name: "proposals-id",
    path: "/proposals/:id()",
    meta: indexxoHW20lNHUiZarjnyRm9EnkuZ5cJNk_45rZY7Jv6LEu8MMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/proposals/[id]/index.vue")
  },
  {
    name: _91teacherId_93gU7Qp5FUHRcuJfFYORUkzZV5jyHLUFUvGdj5NOlpn3EMeta?.name,
    path: "/teachers/:teacherId()",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/teachers/[teacherId].vue"),
    children: [
  {
    name: "teachers-teacherId",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/teachers/[teacherId]/index.vue")
  },
  {
    name: "teachers-teacherId-courses",
    path: "courses",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/teachers/[teacherId]/courses.vue")
  }
]
  },
  {
    name: "videos-playback_id",
    path: "/videos/:playback_id()",
    meta: _91playback_id_937FWbJhZSaWxudbGnQ9F0XOIikdq6dlMtr0NBIppbDQcMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/videos/[playback_id].vue")
  },
  {
    name: "sessions-sessionId",
    path: "/sessions/:sessionId()",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/sessions/[sessionId]/index.vue")
  },
  {
    name: _91organizationId_93XS7bmzsGOQSU9TZrwqMsYGjuZiHGpWOQb1xBmNNDu9EMeta?.name,
    path: "/organizations/:organizationId()",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId].vue"),
    children: [
  {
    name: "organizations-organizationId",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/index.vue")
  },
  {
    name: "organizations-organizationId-tracks",
    path: "tracks",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/tracks.vue")
  },
  {
    name: "organizations-organizationId-credits",
    path: "credits",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/credits.vue")
  },
  {
    name: "organizations-organizationId-sessions",
    path: "sessions",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/sessions.vue")
  },
  {
    name: "organizations-organizationId-invitations",
    path: "invitations",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/invitations.vue")
  }
]
  }
]