import revive_payload_client_h4TwhKo_b4bgGZS09Ra0BFupoR6ckn14vAjM0aKKIqM from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1ikHn_I1MWlO8TRp_vXBNswnfI4G_f9w9vM5mOig5u0 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_u3ZetPjyT_hQCGeYD0_roLaTELJedSc6epkfketE7tI from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_qaX77Ocjx57NKuqomq_AjUbBoZ5_0A26SLhDhmxmjBs from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _0_siteConfig_uL6TPMe0sNSVq2ADYvX4YFrIBZc16pxexrMnmK5Y73A from "/opt/render/project/src/node_modules/.pnpm/nuxt-site-config@3.1.6_magicast@0.3.5_vue@3.5.13_typescript@5.6.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import feature_flags_plugin__F8dkiuMqoTT15JVAyqUHHFXvSi8BzKMxJWQJYleMc0 from "/opt/render/project/src/packages/core/client/plugins/feature-flags.plugin.ts";
import i18n_core_plugin_xwGTXBtUAAnvXA_UN8O5rAf7MrBRm7rvv48H0hWnb1k from "/opt/render/project/src/packages/core/client/plugins/i18n-core.plugin.ts";
import payload_client__72e9Zp6rUv2ijnvlI9IQoJMqYcuM9Vi0Pw2Ivqnotw from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GlMHFUHV6i3dR4zlw2M8bjIKo2mgFRUraLq0G8qdM3w from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_5OdkAkLYERWyxRZMEZCX4DlpgRNGAU7SaOji9qV0kTg from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_AuefUQ4XtykEeFf8j8a54Z0n30hpD0jnM1ZySA5VCN4 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/opt/render/project/src/apps/admin/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_WUcdDb2X93n11up5DSRR8YzaABF_2cZn7COIzV6r0io from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ALbOSdPRj6XRdZk2y8tBRAi3rkili5BHh7tn5x_C_Aw from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.5_@types+node@22.13.9_jiti@2.4.2_sass@1.69.4_stylus_73yby47onwsbkeq7tnms7agpoe/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import event_plugin_client_yr5rcg5m9sRZOSX2aEoUrwEJQ7KGm28UwgeJaBD9QxY from "/opt/render/project/src/packages/core/client/plugins/event.plugin.client.ts";
import hydration_plugin_4u7ge4Fnrrxu3_K2T3vh_ltzvab8ZjV3C5RJejtWPqU from "/opt/render/project/src/packages/core/client/plugins/hydration.plugin.ts";
import monitoring_plugin_client_7cVasBIQ9IJ58_I4ufFAMdzkAE5Uavls07sTKwx18_w from "/opt/render/project/src/packages/core/client/plugins/monitoring.plugin.client.ts";
import form_plugin_j_LZCYn_FgN78VZt0kur4xXaQWbiB1yShZWWXfOUbxs from "/opt/render/project/src/apps/admin/client/plugins/form.plugin.ts";
import theme_plugin_nUU2SBezrFFAOdQ2x3xGuie8PUu6UM_9fnWnknzcSeY from "/opt/render/project/src/apps/admin/client/plugins/theme.plugin.ts";
export default [
  revive_payload_client_h4TwhKo_b4bgGZS09Ra0BFupoR6ckn14vAjM0aKKIqM,
  unhead_1ikHn_I1MWlO8TRp_vXBNswnfI4G_f9w9vM5mOig5u0,
  router_u3ZetPjyT_hQCGeYD0_roLaTELJedSc6epkfketE7tI,
  supabase_client_qaX77Ocjx57NKuqomq_AjUbBoZ5_0A26SLhDhmxmjBs,
  _0_siteConfig_uL6TPMe0sNSVq2ADYvX4YFrIBZc16pxexrMnmK5Y73A,
  feature_flags_plugin__F8dkiuMqoTT15JVAyqUHHFXvSi8BzKMxJWQJYleMc0,
  i18n_core_plugin_xwGTXBtUAAnvXA_UN8O5rAf7MrBRm7rvv48H0hWnb1k,
  payload_client__72e9Zp6rUv2ijnvlI9IQoJMqYcuM9Vi0Pw2Ivqnotw,
  navigation_repaint_client_GlMHFUHV6i3dR4zlw2M8bjIKo2mgFRUraLq0G8qdM3w,
  check_outdated_build_client_5OdkAkLYERWyxRZMEZCX4DlpgRNGAU7SaOji9qV0kTg,
  chunk_reload_client_AuefUQ4XtykEeFf8j8a54Z0n30hpD0jnM1ZySA5VCN4,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_WUcdDb2X93n11up5DSRR8YzaABF_2cZn7COIzV6r0io,
  plugin_ALbOSdPRj6XRdZk2y8tBRAi3rkili5BHh7tn5x_C_Aw,
  event_plugin_client_yr5rcg5m9sRZOSX2aEoUrwEJQ7KGm28UwgeJaBD9QxY,
  hydration_plugin_4u7ge4Fnrrxu3_K2T3vh_ltzvab8ZjV3C5RJejtWPqU,
  monitoring_plugin_client_7cVasBIQ9IJ58_I4ufFAMdzkAE5Uavls07sTKwx18_w,
  form_plugin_j_LZCYn_FgN78VZt0kur4xXaQWbiB1yShZWWXfOUbxs,
  theme_plugin_nUU2SBezrFFAOdQ2x3xGuie8PUu6UM_9fnWnknzcSeY
]